.zh-datePicker .react-datepicker {
    box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 10px;
}

.zh-datePicker .react-datepicker__header {
    background-color: var(--main-color);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.zh-datePicker .react-datepicker__day-name,
.zh-datePicker .react-datepicker__current-month {
    color: rgba(255, 255, 255, 1);
    font-family: "Poppins", sans-serif;
}

.zh-datePicker .react-datepicker__current-month {
    font-weight: 600;
}

.zh-datePicker .react-datepicker__day--selected,
.zh-datePicker .react-datepicker__day--keyboard-selected {
    background-color: var(--main-color);
    color: white;
}

.zh-datePicker .react-datepicker__day--selected:focus-visible {
    outline: none;
}

.zh-datePicker .react-datepicker__day--outside-month {
    color: gray;
}

.zh-datePicker .react-datepicker__navigation-icon {
    top: 4px;
}

.zh-datePicker .react-datepicker__triangle {
    display: none;
}

.zh-datePicker .react-datepicker__navigation-icon::before {
    height: 12px;
    width: 12px;
}

.zh-datePicker .react-datepicker__navigation--previous {
    left: 7px;
}

.zh-datePicker .react-datepicker__navigation--next {
    right: 7px;
}

.react-datepicker-popper {
    z-index: 99;
}