@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --main-color: "#477D53";
  --main-color-light: "#C1EBC5";
  --main-link-color: "#8d866f";
  --main-navigation-color: "#bfbfbf";
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

.invalid-editor-style {
  .editor-container {
    .toolbar {
      background: #cd4c4c1a !important;
    }

    .editor-inner {
      background: #cd4c4c1a !important;
    }
  }
}

.filter-shadow {
  -webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4);
}

/* .side-bar-menu-inner-top-shadow {
  -webkit-box-shadow: inset 0px 21px 43px -44px rgba(0, 0, 0, 0.59);
  -moz-box-shadow: inset 0px 21px 43px -44px rgba(0, 0, 0, 0.59);
  box-shadow:
    inset 0px 21px 43px -44px rgba(0, 0, 0, 0.59),
    0px 1px 30px -21px rgba(0, 0, 0, 0.59);
} */

.module-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.disabled-text-select {
  background-color: transparent;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.disabled-text-select::selection {
  background-color: transparent;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.test-table .test-table-div:nth-child(even) {
  background-color: #f1f1f1;
}

.test-table .test-table-div:nth-child(even):hover {
  background-color: #0000002f;
}

.test-table .test-table-div:first-child:hover {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #0000002f;
}

.atb {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.abb {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.accordion {
  user-select: none;
  margin-bottom: 10px;
}

.accordion-header {
  padding: 20px;
  cursor: pointer;
  align-items: center;
}

.accordion-header i {
  transition: 300ms;
}

.accordion-header i.rotate {
  transform: rotate(180deg);
}

.accordion-body {
  overflow: hidden;
  height: auto;
  max-height: 0;
  width: 100%;
  transition: all 0.2s ease-out;
}

.accordion-body.expand {
  transition: all 0.2s ease-in;
}

@layer base {
  html {
    font-family: "Poppins", sans-serif;
    -webkit-tap-highlight-color: transparent;
  }

  .filters-scrollbox-shadows-down {
    -webkit-box-shadow: 0px 8px 18px -24px #000000;
    box-shadow: 0px 8px 18px -24px #000000;
  }

  .filters-scrollbox-shadows-up {
    -webkit-box-shadow: 0px -8px 18px -24px #000000;
    box-shadow: 0px -8px 18px -24px #000000;
  }

  .preinterview-video-shadow {
    box-shadow:
      inset 0px 200px 150px -94px rgb(0, 0, 0),
      inset 0px -340px 300px -94px rgb(0, 0, 0);
  }

  .editor-input ul,
  menu {
    list-style: disc;
  }
  .editor-input ol {
    list-style: decimal;
  }

  .show-animation {
    animation: fadeInAnimation ease 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .shadow-drop-bottom {
    animation: shadow-drop-bottom 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  /* Microphone Check */
  #volume-bar {
    --volume: 0%;
  }

  #volume-bar::before {
    width: var(--volume);
    transition: width 80ms linear;
  }

  input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
  }

  input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    color: var(--main-color);
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 50%;
    display: grid;
    place-content: center;
    background-color: transparent;
  }

  /* Custom table popover */
  [data-content] > .showPopover {
    display: none;
  }

  [data-content]:hover > .showPopover {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 4px;
    z-index: 100;
    background-color: aqua;
    width: max-content;
    outline: 5px solid green;
    color: white;
    background-color: green;
    border-radius: 8px;
  }

  input[type="radio"].hide-radio {
    visibility: hidden;
  }

  input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--main-color);
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }

  .radio-btn-control {
    font-size: 16px;
    font-weight: 300;
    display: grid;
    grid-template-columns: 1em auto;
    align-items: center;
    gap: 0.5em;
  }

  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .video-mirror-effect {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
  }

  .screen-height-handler {
    min-height: -webkit-fill-available;
    min-height: 100vh;
  }

  .fade-in-top {
    -webkit-animation: fade-in-top 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-top 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes shadow-drop-bottom {
    0% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.35);
    }
  }

  @keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes pulse-custom {
    50% {
      opacity: 0.7;
    }
  }
  .pulse-custom {
    animation: pulse-custom 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
}

.editor-input[contenteditable="false"] {
  padding: 0px;
  margin-top: 5px;
  font-weight: 400;
  min-height: 10px;
}

:root {
  --loading-grey: #ededed;
}

.loading-page {
  background-color: var(--loading-grey);
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    var(--loading-grey);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}

.w-content {
  width: max-content;
}

.horizontal-question .editor-image img,
.horizontal-answer .editor-image img,
.vertical-question .editor-image img,
.vertical-answer .editor-image img,
.split-question .editor-image img {
  width: 100% !important;
  max-width: 100% !important;
}

.horizontal-question .horizontal-question-content .editor-paragraph,
.split-question .split-question-content .editor-paragraph {
  text-align: center;
}

.vertical-question .vertical-question-content .editor-paragraph {
  text-align: left;
}

.zh-apply-form .editor-paragraph {
  text-align: left !important;
}

.justify-safe-center {
  justify-content: safe center;
}

.no-highlight {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.box-shadow {
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
}

.h-fill {
  height: -webkit-fill-available;
}

.min-h-fill {
  min-height: -webkit-fill-available;
}

.zh-progress-table-filter-by-score .css-1dimb5e-singleValue span span {
  display: none;
}

.zh-progress-table-filter-by-score .css-1dimb5e-singleValue span {
  font-weight: normal;
}

.flex-flow {
  flex-flow: column wrap;
}

.zh-input-number:focus {
  outline: none !important;
}

.client-settings-ico path {
  fill: var(--main-link-color);
}

.client-nav-link:hover,
.client-settings-ico:hover path {
  opacity: 0.8;
}

.active-client-nav-link:hover,
.active-client-settings-ico:hover path {
  opacity: 1;
}

.shadow-inner-lg {
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.2); /* Modify values to your preference */
}

.zh-active-menu-link .zh-active-menu-link-border {
  display: block;
}