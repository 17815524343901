.right-modal-open {
    right: 0;
    animation: fadeInRight 0.2s ease-in-out;
}

.right-modal-close {
    animation: fadeOutLeft 0.5s ease-in-out;
    right: -100%;
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(300px);
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOutLeft {
    from {
        right: 0;
        opacity: 1;
    }
    to {
        right: -100%;
        transform: translateX(300px);
    }
}